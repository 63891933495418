import { Route, Routes } from "react-router-dom";
import Radar from "./components/pages/Radar/Radar";
import './styles/App.scss'

const App = () => {
  return (
    <div className="App">
     <Routes>
        <Route path="/" element={<Radar />} />
      </Routes>
    </div>
  );
}

export default App;
