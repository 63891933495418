import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Radars } from "../../../helpers/Radars";
import { getNearestRadar } from "./RadarMapHelper";
import RadarLegend from "./RadarLegend";

const RadarSettings = ({
  radar,
  onRadarChange,
  onChangeLocation,
  imageLoopControls,
  onChangeImageLoopControls,
}) => {
  const [show, setShow] = useState(false);
  const [useNearestRadar, setUseNearestRadar] = useState(false);
  const [errorGetLocationMessage, setErrorGetLocationMessage] = useState("");
  const [isGetLocationLoading, setIsGetLocationLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const useNearestRadar = JSON.parse(localStorage.getItem("useNearestRadar"));
    setUseNearestRadar(useNearestRadar ? true : false);
  }, []);

  const handleRadarChange = (event) => {
    const newRadar = Radars.find((r) => r.key === event.target.value);
    onRadarChange(newRadar);
    localStorage.setItem("radar", JSON.stringify(newRadar));
  };

  const handleUseNearestRadarChange = () => {
    setUseNearestRadar(!useNearestRadar);
    setErrorGetLocationMessage("");
    if (!useNearestRadar) {
      setIsGetLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const nearestRadar = getNearestRadar(
            position.coords.latitude,
            position.coords.longitude
          );
          localStorage.setItem("useNearestRadar", JSON.stringify(true));
          onRadarChange(nearestRadar);
          setIsGetLocationLoading(false);
          localStorage.setItem("radar", JSON.stringify(nearestRadar));
          onChangeLocation([
            position.coords.latitude,
            position.coords.longitude,
          ]);
        },
        () => {
          setUseNearestRadar(false);
          setIsGetLocationLoading(false);
          console.log("Error getting location");
          setErrorGetLocationMessage("Error getting location");
        },
        { timeout: 5000 }
      );
    } else {
      localStorage.removeItem("useNearestRadar");
    }
  };

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Настройки</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className="mb-3">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="useNearestRadar"
                  checked={useNearestRadar}
                  onChange={handleUseNearestRadarChange}
                ></input>
                <label className="form-check-label" htmlFor="useNearestRadar">
                  Най-близък радар <em>(изисква разрешение за локация)</em>
                  {isGetLocationLoading && (
                    <span
                      className="ms-1 spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </label>
              </div>
              {errorGetLocationMessage && (
                <div className="text-danger">{errorGetLocationMessage}</div>
              )}
            </div>
            <div>
              <label htmlFor="radar-select" className="form-label">
                Радар:
              </label>
              <select
                value={radar.key}
                className="form-select"
                id="radar-select"
                aria-label="Default select example"
                onChange={handleRadarChange}
                disabled={useNearestRadar}
              >
                {Radars.map((radar) => (
                  <option key={radar.key} value={radar.key}>
                    {radar.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-4 mx-2">
              <hr />
            </div>
            <div className="mb-3">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="showPlayControls"
                  checked={imageLoopControls}
                  onChange={onChangeImageLoopControls}
                ></input>
                <label className="form-check-label" htmlFor="showPlayControls">
                  Автоматично превъртане
                </label>
              </div>
            </div>
            <hr />
            <div className="mb-3">
              <h5 className="mb-3">Легенда</h5>
              <RadarLegend></RadarLegend>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="radar-settings">
        <div className="d-flex">
          <div
            className="d-flex justify-content-center align-items-center px-2"
            onClick={handleShow}
          >
            <i className="bi bi-gear-fill"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadarSettings;
