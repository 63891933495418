export const Radars = [
  {
    id: 1,
    key: "BRD",
    name: "Враца (Радар Бърдарски геран)",
    dataBounds: [
      [46.158, 27.644],
      [40.809, 20.264]
    ],
    location: [43.54243, 23.95395]
  },
  {
    id: 2,
    key: "STS",
    name: "Сливен (Старо село)",
    dataBounds: [
      [45.18, 29.773],
      [39.832, 22.511]
    ],
    location: [42.56213, 26.14222]
  },
  {
    id: 3,
    key: "GCD",
    name: "Пловдив (Радар Голям чардак)",
    dataBounds: [
      [44.919, 28.251],
      [39.57, 21.019]
    ],
    location: [42.30050, 24.63382]
  },
  {
    id: 4,
    key: "POP",
    name: "Пловдив (Поповица)",
    dataBounds: [
      [44.739, 28.676],
      [39.394, 21.469]
    ],
    location: [42.12230, 25.07227]
  },
  {
    id: 5,
    key: "DCE",
    name: "Монтана (Долно Церовене)",
    dataBounds: [
      [46.204, 26.927],
      [40.859, 19.547]
    ],
    location: [43.59072, 23.23550]
  },
  {
    id: 6,
    key: "JAR",
    name: "София (Ярлово)",
    dataBounds: [
      [44.457, 25.757],
      [40.718, 20.424]
    ],
    location: [42.47747, 23.22549]
  },
  {
    id: 7,
    key: "SMN",
    name: "Шумен",
    dataBounds: [
      [45.876, 30.589],
      [40.523, 23.239]
    ],
    location: [43.25897, 26.91457]
  }
]
