import { useEffect, useState } from "react";
import { Radars } from "../../../helpers/Radars";
import Loader from "../../shared/Loader";
import { getNearestRadar } from "./RadarMapHelper";
import RadarMapWithControls from "./RadarMapWithControls";
import RadarSettings from "./RadarSettings";

const Radar = () => {
  const [radar, setRadar] = useState({});
  const [userLocation, setUserLocation] = useState([]);
  const [showImageLoopControls, setImageLoopControls] = useState(false);

  useEffect(() => {
    const savedRadar = JSON.parse(localStorage.getItem("radar"));
    const useNearestRadar = JSON.parse(localStorage.getItem("useNearestRadar"));
    const savedShowImageLoopControls = JSON.parse(
      localStorage.getItem("showImageLoopControls")
    );
    setImageLoopControls(savedShowImageLoopControls ? true : false);
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setUserLocation([position.coords.latitude, position.coords.longitude]);
        if (useNearestRadar) {
          const nearestRadar = getNearestRadar(
            position.coords.latitude,
            position.coords.longitude
          );
          setRadar(nearestRadar);
          localStorage.setItem("radar", JSON.stringify(nearestRadar));
        } else if (savedRadar) {
          setRadar(savedRadar);
        } else {
          setRadar(Radars[0]);
        }
      },
      () => {
        console.log("Error getting location");
        if (savedRadar) {
          setRadar(savedRadar);
        } else {
          setRadar(Radars[0]);
        }
      },
      {timeout:5000}
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowImageLoopControls = () => {
    localStorage.setItem(
      "showImageLoopControls",
      JSON.stringify(!showImageLoopControls)
    );
    setImageLoopControls(!showImageLoopControls);
  };

  return (
    <>
      {!radar.id && (
        <Loader />
      )}
      {radar.id && (
        <div>
          <RadarSettings
            radar={radar}
            onRadarChange={setRadar}
            onChangeLocation={setUserLocation}
            imageLoopControls={showImageLoopControls}
            onChangeImageLoopControls={handleShowImageLoopControls}
          />

          <RadarMapWithControls
            radar={radar}
            userLocation={userLocation}
            imageLoopControls={showImageLoopControls}
          />
        </div>
      )}
    </>
  );
};

export default Radar;
