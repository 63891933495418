const dBZscale = [
  {
    color: "#B676FA",
    range: "70- >75dBZ",
    text: "Много силен дъжд и интензивна едрозърнеста градушка",
  },
  {
    color: "#CBA8FE",
    range: "65-70dBZ",
    text: "Много силен дъжд и интензивна едрозърнеста градушка",
  },
  {
    color: "#FFA1FD",
    range: "60-65dBZ",
    text: "Много силен дъжд, мълнии и градушка и вероятност за едрозърнеста градушка",
  },
  {
    color: "#CA0090",
    range: "55-60dBZ",
    text: "Много силен дъжд, , мълнии  и вероятност за градушка",
  },
  {
    color: "#000000",
    range: "50-55dBZ",
    text: "Силен дъжд, мълнии и вероятност за суграшица",
  },
  {
    color: "#EFEBE8",
    range: "45-50dBZ",
    text: "Умерен до силен дъжд и мълнии",
  },
  {
    color: "#F30107",
    range: "40-45dBZ",
    text: "Умерен дъжд и вероятност за мълнии",
  },
  {
    color: "#FFAD00",
    range: "35-40dBZ",
    text: "Умерен дъжд",
  },
  {
    color: "#FFFE16",
    range: "30-35dBZ",
    text: "Слаб до умерен дъжд",
  },
  {
    color: "#008424",
    range: "25-30dBZ",
    text: "Слаб дъжд",
  },
  {
    color: "#00C039",
    range: "20-25dBZ",
    text: "Много слаб дъжд",
  },
  {
    color: "#26FF25",
    range: "15-20dBZ",
    text: "Облачност без валеж",
  },
  {
    color: "#47ABFD",
    range: "10-15dBZ",
    text: "Облачност без валеж",
  },
  {
    color: "#2AD7FF",
    range: "5-10dBZ",
    text: "Очертание на облачността",
  },
  {
    color: "#91C9CF",
    range: "0-5dBZ",
    text: "Очертание на облачността",
  },
];
const RadarLegend = () => {
  return (
    <>
      <table class="table">
        <tbody>
          {dBZscale.map((item) => {
            return (
              <tr>
                <td style={{ backgroundColor: item.color }}></td>
                <td><strong>{item.range}</strong> {item.text}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default RadarLegend;
