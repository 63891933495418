const headers = (params = {}, headers = {}) => {
    return {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'Accept-Language': 'en',
            ...headers,
        },
        params: {
            ...params,
        },
    }
}
export default headers
