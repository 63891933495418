import { LatLngBounds } from "leaflet";
import Slider from "rc-slider";
import { useEffect, useRef, useState } from "react";
import { ImageOverlay, MapContainer, Marker, TileLayer } from "react-leaflet";
import { fetchRadarData } from "./RadarMapHelper";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const RadarMapWithControls = ({ radar, userLocation, imageLoopControls }) => {
  const [radarData, setRadarData] = useState(null);
  const [currentImage, setCurrentImage] = useState(1);
  const [imagesCount, setImagesCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loopImages, setLoopImages] = useState(false);

  const intervalRef = useRef();
  const intervalRefLoopImages = useRef();

  useEffect(() => {
    refreshRadar();
    clearInterval(intervalRef);
    intervalRef.current = setInterval(() => {
      refreshRadar();
    }, 150000);

    return () => {
      clearInterval(intervalRef);
    };
  }, [radar]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshRadar = () => {
    if (!isLoading) {
      setIsLoading(true);
      setHasError(false);
      fetchRadarData(radar.key)
        .then((result) => {
          setRadarData(result?.data?.img_list);
          setCurrentImage(Object.keys(result?.data?.img_list).length);
          setImagesCount(Object.keys(result?.data?.img_list).length);
          setIsLoading(false);
        })
        .catch((error) => {
          setHasError(true);
          setIsLoading(false);
        });
    }
  };

  const getLatLngBounds = (bounds) => {
    return new LatLngBounds(bounds);
  };

  const handleChangeLoopImages = () => {
    setCurrentImage(1);
    setLoopImages(!loopImages);
  };

  useEffect(() => {
    if (loopImages) {
      intervalRefLoopImages.current = setInterval(() => {
        if (currentImage === imagesCount) {
            setLoopImages(false)
            clearInterval(intervalRefLoopImages.current);
            intervalRefLoopImages.current = null;
        } else {
            setCurrentImage(currentImage + 1);
        }
      }, 100);
    } else {
        clearInterval(intervalRefLoopImages.current);
        intervalRefLoopImages.current = null;
    }
    return () => clearInterval(intervalRefLoopImages.current);
  }, [loopImages, currentImage, imagesCount]);

  useEffect(() => {
    if (!imageLoopControls) {
      setLoopImages(false);
    }
  }, [imageLoopControls]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef);
      clearInterval(intervalRefLoopImages);
    };
  }, []);

  return (
    <>
      <div className="radar-controls">
        <div className="d-flex">
          {imageLoopControls && (
            <div
              className="d-flex justify-content-center align-items-center px-2"
              onClick={() => {
                handleChangeLoopImages();
              }}
            >
              {!loopImages && <i className="bi bi-play-fill"></i>}
              {loopImages && <i className="bi bi-pause-fill"></i>}
            </div>
          )}
          <div className="w-100">
            {radarData && radarData[currentImage]?.timp}
            <div className="slider">
              {radarData && (
                <Slider
                  min={1}
                  max={imagesCount}
                  value={currentImage}
                  onChange={setCurrentImage}
                />
              )}
            </div>
          </div>

          <div
            className="d-flex justify-content-center align-items-center px-2"
            disabled={isLoading}
            onClick={() => {
              refreshRadar();
            }}
          >
            {!isLoading && (
              <i
                className={`bi bi-arrow-clockwise icon-button ${
                  hasError ? "text-danger" : ""
                }`}
              ></i>
            )}
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </div>
        </div>
      </div>
      <MapContainer
        attributionControl={false}
        center={userLocation.length > 0 ? userLocation : radar.location}
        zoom={userLocation.length > 0 ? 10 : 9}
        scrollWheelZoom={true}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {radarData && (
          <ImageOverlay
            alt="Radar data overlay"
            url={radarData[currentImage]?.wh_img}
            bounds={getLatLngBounds(radar.dataBounds)}
            opacity={0.5}
            zIndex={500}
          ></ImageOverlay>
        )}
        {userLocation.length > 0 && <Marker position={userLocation}></Marker>}
      </MapContainer>
    </>
  );
};
export default RadarMapWithControls;
