import axios from "axios";
import headers from "../../../helpers/Headers";
import { Radars } from "../../../helpers/Radars";

export const fetchRadarData = (radar) => {
  return axios.get(
    "https://www.weathermod-bg.eu/wr/img_data.php?srs=" + radar,
    headers()
  );
};

export const getNearestRadar = (lat, lng) => {
  const nearestRadar = Radars.reduce((prev, curr) => {
    const prevDistance = getDistance(
      lat,
      lng,
      prev.location[0],
      prev.location[1]
    );
    const currDistance = getDistance(
      lat,
      lng,
      curr.location[0],
      curr.location[1]
    );
    return prevDistance < currDistance ? prev : curr;
  }, Radars[0]);
  return nearestRadar;
};

const getDistance = (lat1, lng1, lat2, lng2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};
